import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/SEO';

import TermsSection from '../components/CommonSections/TermsSection';

const PrivacyPage = () => (
  <>
    <SEO title="Christmas Giveaway Terms and Conditions" />
    <TermsSection 
      title="Christmas Giveaway Terms and Conditions"
      content={(<SecondSection />)}
    />
  </>
);

const SecondSection = () => (
    <>
      <h2>Giveaway Campaign: Voucher Terms and Conditions</h2>
      <p>
        We are giving away a voucher to one of our lucky followers. To be entered in the giveaway:
      </p>
      <p>
        <ul>
          <p>
            1. You must be following <a
              href="https://www.instagram.com/spriveapp/"
              target="_blank"
              rel="noopener noreferrer"
            >@spriveapp</a> on Instagram
          </p>
          <p>
            2. You must tag 2 people in the comments of the giveaway post between Monday 00:00 and 23:59 Thursday for each week the campaign is running between 27th November 2023 and Friday 22nd December 2023.          
          </p>
          <p>
            3. You must reshare the post on your story between Monday 00:00 and 23:59 Thursday for each week the campaign is running between 27th November 2023 and Friday 22nd December 2023. (If you are a private account, please send us a screenshot via Instagram DM as proof)
          </p>
        </ul>
      </p>

      <p>
      The winner will be chosen at random and will be contacted from our Instagram DM by each Friday until the campaign finishes on 22nd December.
      </p>

      <p>
        We will only contact you from our official Sprive IG account <a
              href="https://www.instagram.com/spriveapp/"
              target="_blank"
              rel="noopener noreferrer"
            >@spriveapp</a>
      </p>

    </>
);

export default PrivacyPage;
